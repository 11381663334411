import React from 'react';
import { Route } from 'react-router-dom';

//
//
//
// management
//
// 1) student
//
const StudentList = React.lazy(
  () => import('pages/management/StudentList/StudentList'),
);
const StudentDetail = React.lazy(
  () => import('pages/management/StudentDetail/StudentDetail'),
);
const StudentAddEdit = React.lazy(
  () => import('pages/management/StudentAddEdit/StudentAddEdit'),
);
const StudentAddMultiple = React.lazy(
  () => import('pages/management/StudentAddMultiple/StudentAddMultiple'),
);

//
// 2) teacher
//
const TeacherAddEdit = React.lazy(
  () => import('pages/management/TeacherAddEdit/TeacherAddEdit'),
);
const TeacherList = React.lazy(
  () => import('pages/management/TeacherList/TeacherList'),
);
const TeacherDetail = React.lazy(
  () => import('pages/management/TeacherDetail/TeacherDetail'),
);

//
// 3) cls
//
const ClsAddEdit = React.lazy(
  () => import('pages/management/ClsAddEdit/ClsAddEdit'),
);
const ClsList = React.lazy(() => import('pages/management/ClsList/ClsList'));
const ClsDetail = React.lazy(
  () => import('pages/management/ClsDetail/ClsDetail'),
);

//
// 4) academy
//

const AcademyDetail = React.lazy(
  () => import('pages/management/AcademyDetail/AcademyDetail'),
);
const AcademyEdit = React.lazy(
  () => import('pages/management/AcademyEdit/AcademyEdit'),
);

//
// 5) 업무
//

const Dashboard = React.lazy(
  () => import('pages/management/Dashboard/Dashboard'),
);

const CounselLog = React.lazy(
  () => import('pages/management/CounselLog/CounselLog'),
);

const Workroom = React.lazy(() => import('pages/management/Workroom/Workroom'));

//
// 6) 초대
//

const InvitationMessage = React.lazy(
  () => import('pages/management/InvitationMessage/InvitationMessage'),
);

const RouterListTSManagement = (
  <Route path="/teacherSide/management/">
    {/* 관리화면 - 학생 */}
    <Route path="student/">
      <Route path="list" element={<StudentList />} />
      <Route path="add" element={<StudentAddEdit mode="add" />} />
      <Route path="addMultiple" element={<StudentAddMultiple />} />
      <Route path="edit/:studentId" element={<StudentAddEdit mode="edit" />} />
      <Route path="detail/:studentId" element={<StudentDetail />} />
    </Route>

    {/* 관리화면 - 교사 */}
    <Route path="teacher/">
      <Route path="list" element={<TeacherList />} />
      <Route path="add" element={<TeacherAddEdit mode="add" />} />
      <Route path="edit/:teacherId" element={<TeacherAddEdit mode="edit" />} />
      <Route path="detail/:teacherId" element={<TeacherDetail />} />
    </Route>

    {/* 관리화면 - 클래스 */}
    <Route path="cls/">
      <Route path="list" element={<ClsList />} />
      <Route path="add" element={<ClsAddEdit mode="add" />} />
      <Route path="edit/:clsId" element={<ClsAddEdit mode="edit" />} />
      <Route path="detail/:clsId" element={<ClsDetail />} />
    </Route>

    {/* 관리화면 - 원 */}
    <Route path="academy/">
      <Route path="detail" element={<AcademyDetail />} />
      <Route path="edit" element={<AcademyEdit />} />
    </Route>

    {/* 요약 */}
    <Route path="dashboard" element={<Dashboard />} />

    {/* 상담 기록 */}
    <Route path="counselLog" element={<CounselLog />} />

    {/* 작업실 */}
    <Route path="workroom" element={<Workroom />} />

    {/* 초대 */}
    <Route path="invitationMessage" element={<InvitationMessage />} />
  </Route>
);

export default RouterListTSManagement;
