/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios';
import Config from 'config/Config';
import { API } from 'types/frontend/shared';
import Token from 'utils/Token';
import interfaceBridge from 'utils/interfaceBridge';
import { handleDates } from './dateHandler';
import AccessTokenManager from 'utils/AccessTokenManager';

const instance = axios.create({
  baseURL: Config.apiBaseUrl,
  timeout: 30000,
  headers: { 'X-Custom-Header': 'foobar' }, // 토큰은 여기 넣지 말고 실시간으로 넣어주자
});

// 여기서의 data변경은 interceptor를 거치는지와 상관없이 항상 적용된다.
const responseBody = <T>(response: AxiosResponse<T>) => {
  if (!response) return response;
  // 받은 내용 중 iso date형식 string을 Date로 변환
  handleDates(response.data);
  return response.data as T;
};

// restapi method별 함수 래핑
const requests = {
  get: <RES>(url: string, params: {}) =>
    instance.get<RES>(url, { params }).then(responseBody),
  post: <RES>(url: string, body: {}) =>
    instance.post<RES>(url, body).then(responseBody),
  put: <RES>(url: string, body: {}) =>
    instance.put<RES>(url, body).then(responseBody),
  delete: <RES>(url: string) => instance.delete<RES>(url).then(responseBody),
};

const api = <REQ, RES>(api: API, request: REQ): Promise<RES> =>
  requests[api.method.toLocaleLowerCase()]<RES>(
    api.path,
    request,
  ) as Promise<RES>;

// 인터셉터 설정하기
instance.interceptors.request.use(
  function (config) {
    const accessToken = Token.getToken('accessToken');
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log('interceptors response error');
    if (error.config && error.response && error.response.status === 401) {
      const originalRequest = error.config;
      originalRequest._retry = true;

      try {
        console.log(
          '새 access token 발급 시도, org request=',
          originalRequest.url,
        );
        const newAccessToken = await AccessTokenManager.getNewAccessToken();

        if (
          !newAccessToken &&
          AccessTokenManager.accessTokenState === 'ERROR'
        ) {
          // 다시 발급받기 실패
          interfaceBridge.goToSignIn();
          return Promise.reject(error);
        }

        originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
        const res = await axios(originalRequest);
        return res;
      } catch (error: unknown) {
        // access_token 갱신에 실패했으므로
        // 로그인 화면으로 가야함
        return Promise.reject(error);
      }
    } // end of 401

    if (error.config && error.response && error.response.status === 500) {
      // 서버 오류
    }

    return Promise.reject(error);
  },
);

const download = async <REQ>(
  url: string,
  params: REQ,
  expectedExt: 'xlsx' | 'txt',
): Promise<unknown> => {
  const extractFileName = (response: AxiosResponse<Blob>): string => {
    let filename = `unknown.${expectedExt}`; // Default filename if none is found
    for (const [key, value] of Object.entries(response.headers)) {
      if (key.toLowerCase() === 'content-disposition') {
        // Ensure that we're only dealing with a single string, handle arrays if necessary
        const disposition = Array.isArray(value) ? value.join('; ') : value;
        const filenameMatch = disposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i,
        );
        if (filenameMatch && filenameMatch[1]) {
          // Remove any surrounding quotes (single or double) and spaces
          filename = filenameMatch[1].trim().replace(/['"]/g, '');
          break; // Stop looping once the filename is found
        }
      }
    }
    return filename;
  };

  try {
    const response = await instance.get<Blob>(url, {
      params,
      responseType: 'blob',
    });

    // Check the content-type of the response
    const contentType = response.headers['content-type'];
    if (contentType.includes('application/json')) {
      // Assume it's an error response if JSON, since successful binary shouldn't be JSON
      const text = await new Response(response.data).text();
      try {
        const jsonResponse = JSON.parse(text);
        return jsonResponse;
      } catch (e) {
        throw new Error('Malformed JSON response while downloading');
      }
    }

    const filename = extractFileName(response);
    const blobUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType }),
    );
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error; // Re-throw to allow the caller to handle
  }
};

export { api, download };

export default instance;
